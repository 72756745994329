import { render, staticRenderFns } from "./PartnerSwiperList.vue?vue&type=template&id=0f09b136&scoped=true&"
import script from "./PartnerSwiperList.vue?vue&type=script&lang=ts&"
export * from "./PartnerSwiperList.vue?vue&type=script&lang=ts&"
import style0 from "./PartnerSwiperList.vue?vue&type=style&index=0&id=0f09b136&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f09b136",
  null
  
)

export default component.exports