
import { defineComponent } from '@nuxtjs/composition-api'
import SvoiButtonLink from '@svoi-ui/components/button/SvoiButtonLink.vue'

export default defineComponent({
  name: 'StudentActivitiesBanner',
  components: {
    SvoiButtonLink
  }
})
