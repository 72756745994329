
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import AppLazyImage from '~/components/UI/AppLazyImage.vue'

@Component({
  components: {
    AppLazyImage
  }
})
export default class PartnerCard extends Vue {
  @Prop({
    default: ''
  })
  logo!: string

  get getLogoUrl() {
    return this.$services.image.cropImage(this.logo, {
      mode: 'auto'
    })
  }
}
