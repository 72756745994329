import { render, staticRenderFns } from "./VseSvoeBanner.vue?vue&type=template&id=cc83ff50&scoped=true&"
import script from "./VseSvoeBanner.vue?vue&type=script&lang=ts&"
export * from "./VseSvoeBanner.vue?vue&type=script&lang=ts&"
import style0 from "./VseSvoeBanner.vue?vue&type=style&index=0&id=cc83ff50&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc83ff50",
  null
  
)

export default component.exports