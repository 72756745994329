
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import AppLazyImage from '~/components/UI/AppLazyImage.vue'

@Component({
  components: { AppLazyImage }
})
export default class MainTelegramBanner extends Vue {
  @Prop({
    default: 'green'
  })
  theme!: string

  @Prop({
    default: ''
  })
  name!: string

  @Prop({
    default: ''
  })
  description!: string

  @Prop({
    default: ''
  })
  buttonName!: string

  @Prop({
    default: ''
  })
  url!: string

  @Prop({
    default: ''
  })
  image!: string

  @Prop({
    default: ''
  })
  imageMobile!: string

  @Prop({
    default: 'main'
  })
  buttonTheme!: string

  get classes() {
    return {
      [`theme-${this.theme}`]: true
    }
  }

  onClickButton() {
    this.$emit('on-click-button')
  }
}
