
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import AppLazyImage from '~/components/UI/AppLazyImage.vue'

@Component({
  components: {
    AppLazyImage
  }
})
export default class VseSvoeBanner extends Vue {
  @Prop()
  public title!: string

  @Prop()
  public subTitle!: string

  @Prop()
  public link!: string

  @Prop()
  public desktopImg!: string

  @Prop()
  public tabletImg!: string

  @Prop()
  public mobileImg!: string

  @Prop()
  public right!: string
}
