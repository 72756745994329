
import { defineComponent } from '@nuxtjs/composition-api'
import SvoiButton from '@svoi-ui/components/button/SvoiButton.vue'

export default defineComponent({
  name: 'YouthCardBanner',
  components: {
    SvoiButton
  },
  setup() {
    const goToPath = () => {
      window.location.href = 'https://svoevagro.ru/rshb/cards/junior-debet'
    }

    return {
      goToPath
    }
  }
})
