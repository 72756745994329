
import { defineComponent } from '@nuxtjs/composition-api'
import SvoiButton from '@svoi-ui/components/button/SvoiButton.vue'

export default defineComponent({
  name: 'OlympiadBanner',
  components: {
    SvoiButton
  }
})
