
import { Component, Vue } from 'nuxt-property-decorator'
import SvoiButton from '@svoi-ui/components/button/SvoiButton.vue'
import AppLazyImage from '~/components/UI/AppLazyImage.vue'

@Component({
  components: {
    AppLazyImage,
    SvoiButton
  }
})
export default class DigitalProfileBanner extends Vue {
  goToDigitalProfileCreate() {
    const url = '/digital-profile/create'
    if (!this.$tstore.auth.isLogged) {
      this.$tstore.auth.setShowModalAuth({ show: true, backUrl: url })
      return
    }

    this.$router.push(url)
  }
}
