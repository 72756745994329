
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { PartnerModel } from '~/model'
import PartnerCard from '~/components/Page/Main/PartnerCard.vue'
import RoundButton from '~/components/UI/RoundButton.vue'

@Component({
  components: {
    PartnerCard,
    RoundButton
  }
})
export default class PartnerSwiperList extends Vue {
  @Prop({
    default: () => []
  })
  partners!: PartnerModel[]

  swiperOption = {
    spaceBetween: 20,
    lazy: true,
    slidesPerView: 6,
    navigation: {
      nextEl: '#next-el',
      prevEl: '#prev-el'
    },
    pagination: {
      el: '.swiper-pagination'
    },
    breakpoints: {
      0: {
        slidesPerView: 1
      },
      499: {
        slidesPerView: 1
      },
      721: {
        slidesPerView: 3
      },
      980: {
        slidesPerView: 4
      },
      1440: {
        slidesPerView: 6
      }
    }
  }
}
