
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api'
import SvoiButton from '@svoi-ui/components/button/SvoiButton.vue'

export default defineComponent({
  name: 'EdwicaBanner',
  components: {
    SvoiButton
  },
  setup() {
    const context = useContext()

    const nameButton = computed((): string =>
      context.$tstore.auth.isLogged ? 'Узнать больше' : 'Перейти в Личный кабинет'
    )

    const goToEdwica = () => {
      if (!context.$tstore.auth.isLogged) {
        context.$tstore.auth.setShowModalAuth({ show: true, backUrl: '/account' })
        return
      }

      window.location.href = `https://svoevagro.edwica.ru/login-svoevagro?token=${context.$tstore.auth.authorizedUser?.idForIntegrations}`
    }

    return {
      goToEdwica,
      nameButton
    }
  }
})
