
export default {
  name: 'BaseYavagroUniversityInfo',
  props: {
    name: {
      type: String,
      required: true
    },
    count: {
      type: Number,
      required: true
    },
    backgroundColor: {
      type: String,
      default: undefined
    },
    icon: {
      type: String,
      default: undefined
    },
    isSelf: {
      type: Boolean,
      default: false
    },
    href: {
      type: String,
      default: undefined
    }
  },
  methods: {
    countCalculate(count) {
      return count.toLocaleString()
    }
  }
}
